/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { useRouter } from "next/router";
import { parseJwt } from "../helpers/functions";

const USER_BY_ID = gql`
  query USER_BY_ID($id: uuid!) {
    user_by_pk(id: $id) {
      city
      country
      email
      firstName
      lastName
      id
      customerNumber
      office
      phone
      role
      zip
      street
      createdAt
      isColosseum
      isDesos
      manager {
        email
      }
      managedUsers {
        email
        city
        street
        customerNumber
      }
    }
  }
`;

const defaultClaims = {
  "https://hasura.io/jwt/claims": {
    "X-Hasura-User-Id": undefined,
    "X-Hasura-Default-Role": undefined,
  },
};

const defaultContextValue = {
  claims: defaultClaims,
  isAdmin: false,
  isColosseumManager: false,
  isColosseumUser: false,
  isDesos: false,
  userData: undefined,
  called: false,
  token: undefined,
  refetch: ({ variables: { id } }) => {},
};

export const UserContext = createContext(defaultContextValue);

const UserContextProvider = (props) => {
  const [getUser, getUserResponse] = useLazyQuery(USER_BY_ID);
  const { children } = props;
  const [claims, setClaims] = useState(defaultClaims);
  const token = typeof window === "undefined" ? undefined : localStorage.getItem("jwtToken");
  useEffect(() => {
    if (typeof window === "undefined" || !token) return;
    const parsed = parseJwt(token);
    const userId = parsed["https://hasura.io/jwt/claims"]["X-Hasura-User-Id"];
    setClaims(parsed);
    getUser({ variables: { id: userId } });
  }, [token]);

  if (getUserResponse.called && getUserResponse.data && !getUserResponse?.data.user_by_pk) {
    console.warn("You probably have a token for a user that doesnt exist anymore");
  }

  const isAdmin = claims["https://hasura.io/jwt/claims"]["X-Hasura-Default-Role"] === "admin";
  const isColosseumManager = claims["https://hasura.io/jwt/claims"]["X-Hasura-Default-Role"] === "colosseum";
  const isColosseumUser = getUserResponse.data?.user_by_pk.isColosseum;
  const isDesos = getUserResponse.data?.user_by_pk.isDesos;
  return (
    <UserContext.Provider
      value={{
        claims,
        isAdmin,
        isColosseumManager,
        isColosseumUser,
        isDesos,
        userData: getUserResponse.data && getUserResponse.data.user_by_pk,
        called: getUserResponse.called && !getUserResponse.loading,
        token,
        refetch: getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) throw new Error("UserContext must be used with UserContextProvider!");
  return context;
};
